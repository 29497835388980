import React, { useState, useEffect } from 'react'
import { resizing } from '../../assets/functions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import Axios from 'axios'
import './Contact.scss'

const Contact = () => {
    const [formInputs, setInputs] = useState({
        name: '',
        email: '',
        message: ''
    })

    useEffect(() => {
        resizing('contactCont')
    }, [])

    const submit = () => {
        const { name, email, message } = formInputs
        let regex = RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)


        if (!name || !email || !message) {
            document.querySelector('.err').innerHTML = 'Please make sure all fields are filled'
            document.querySelector('.err').style.padding = '5px'
            return
        } else {
            document.querySelector('.err').innerHTML = ''
            document.querySelector('.err').style.padding = '0'
        }
        if (!regex.test(email)) {
            document.querySelector('.errEmail').innerHTML = 'Invalid Email'
            document.querySelector('.errEmail').style.padding = '5px'
            return
        } else {
            document.querySelector('.errEmail').innerHTML = ''
            document.querySelector('.errEmail').style.padding = '0px'
        }

        document.querySelector('.flexedForm button').disabled = true

        Axios.post('/email', formInputs).then(res => {
            setInputs({
                name: '',
                email: '',
                message: ''
            })
            document.querySelector('.flexedForm button').disabled = false
            document.querySelector('.emailToastCont').style.bottom = '50px'
            document.querySelector('.emailToastCont').style.opacity = 1
            setTimeout(() => {
                document.querySelector('.emailToastCont').style.bottom = '-75px'
            }, 2000);

        }).catch(err => {
            document.querySelector('.err').innerHTML = 'Oops, something went wrong. Please try again later.'
            document.querySelector('.err').style.padding = '5px'
        })
    }
    return (
        <div className='contactCont'>
            <div className='titleContainer'>
                <h3>Contact</h3>
                <span></span>
            </div>
            <div className='flexedForm'>
                <p>
                    <span>Looking to collaborate or have a potential hiring opportunity in the Greater NYC area? <br /> <br />Leave me a message or contact me at <a href='mailto:levitasmc@gmail.com'>Levitasmc@gmail.com</a></span>
                    <span className='socialLinks'>
                        <a href='https://github.com/mlevitas94' target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={faGithub} /></a>
                        <a href='https://www.linkedin.com/in/mark-levitas/' rel="noopener noreferrer" target='_blank'><FontAwesomeIcon icon={faLinkedin}/></a>
                    </span>
                </p>

                <form>
                    <div className='topInputs'>
                        <div>
                            <label htmlFor='name'>Name</label>
                            <input value={formInputs.name} onChange={(e) => setInputs({ ...formInputs, name: e.target.value })} type='text' maxLength='50' id='name' />
                        </div>

                        <div>
                            <label htmlFor='email'>Email <span className='errEmail'></span></label>
                            <input value={formInputs.email} onChange={(e) => setInputs({ ...formInputs, email: e.target.value })} type='text' maxLength='50' id='email' />
                        </div>
                    </div>

                    <label htmlFor='message'>Message</label>
                    <textarea value={formInputs.message} onChange={(e) => setInputs({ ...formInputs, message: e.target.value })} maxLength='2000' id='message' rows='5'></textarea>
                    <span className='err'></span>
                    <button onClick={(e) => {
                        e.preventDefault()
                        submit()
                    }}>Submit</button>
                </form>
            </div>
            <div className='emailToastCont'>
                <div className='emailToast'>Message Sent</div>
            </div>
        </div>
    )
}

export default Contact