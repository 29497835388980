import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import './Nav.scss'

const Nav = () => {
    window.addEventListener('scroll', () => {
        if (window.scrollY > 0) {
            // multiple styles under one query selector???? 
            document.querySelector('.navCont').style.backgroundColor = '#9c7235'
            document.querySelector('.navCont').style.boxShadow = '0px 4px 20px 1px rgb(19, 19, 19)'
            document.querySelector('.navCont .navLine').style.width = '100%'
        } else {
            document.querySelector('.navCont').style.backgroundColor = 'transparent'
            document.querySelector('.navCont').style.boxShadow = '0px 4px 20px 1px transparent'
            document.querySelector('.navCont .navLine').style.width = '0%'
        }
    })
    window.addEventListener('resize', () => {
        if (window.innerWidth < 750) {
            if (window.scrollY > 0) {
                document.querySelector('.navCont').style.backgroundColor = '#9c7235'
                document.querySelector('.navCont').style.boxShadow = '0px 4px 20px 1px rgb(19, 19, 19)'
                document.querySelector('.navCont .navLine').style.width = '100%'

            }
        } else {
            if (window.scrollY <= 0) {

                document.querySelector('.navCont .navLine').style.width = '0%'
            }
        }
    })
    return (
        <div className='navCont'>
            <ul>
                <li onClick={() => { window.scrollBy({ top: document.querySelector('.aboutCont').getBoundingClientRect().y - 100, behavior: 'smooth' }) }}>About</li>
                <li onClick={() => { window.scrollBy({ top: document.querySelector('.skillsCont').getBoundingClientRect().y - 100, behavior: 'smooth' }) }}>Skills</li>
                <li onClick={() => { window.scrollBy({ top: document.querySelector('.projectsCont').getBoundingClientRect().y - 100, behavior: 'smooth' }) }}>Projects</li>
                <li onClick={() => { window.scrollBy({ top: document.querySelector('.contactCont').getBoundingClientRect().y - 100, behavior: 'smooth' }) }}>Contact</li>
            </ul>
            <div className='navLine'></div>
            <a href='https://drive.google.com/file/d/1TQhUzDKguFdsGm5Aeu1AKOmFReJKOBdd/view?usp=sharing' target='_blank' rel="noopener noreferrer"><div className='navResume'><FontAwesomeIcon icon={faFilePdf} /></div></a>
        </div>
    )
}

export default Nav