import React, {useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

const Project = (props) => {
    const { project, i } = props

    useEffect(() => {
        document.querySelector(`.project-${i} .info`).style.backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), url(${project.images[0]})`
    })

    return (
        <div className={`project project-${i}`}>
            <div className='overlayCont'>
                <div className='info'>
                    <h5>{project.type}</h5>
                    <div className='infoFlex'>
                        <h4>{project.title}</h4>
                        <p>{project.description}</p>
                        <div className='infoBottom'>
                            <p>{project.techs}</p>
                            <div className='links'>
                                {project.github === null ? null :
                                    <a href={project.github} target='_blank' rel="noopener noreferrer" ><FontAwesomeIcon icon={faGithub} /></a>}
                                {project.link === null ? null :
                                    <a href={project.link} target='_blank' rel="noopener noreferrer" ><FontAwesomeIcon icon={faExternalLinkAlt} /></a>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='imgCont'>
                    <a href={project.link} target='_blank' rel="noopener noreferrer" ><div className='overlay'></div></a>
                    <img src={project.images[0]} alt='Project Logo' />

                </div>
            </div>
        </div>
    )
}

export default Project