import React from 'react';
import Nav from './Components/Nav/Nav'
import MainClip from './Components/MainClip/MainClip';
import About from './Components/About/About'
import Skills from './Components/Skills/Skills';
import Contact from './Components/Contact/Contact';
import Projects from './Components/Projects/Projects';

const App = () => {
  const isScrolledIntoView = (el) => {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;
    var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight + 575);
    return isVisible;
  }


  const isScrolledIntoViewPadded = (el) => {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;
    var isVisible = (elemTop >= 50) && (elemBottom <= window.innerHeight - 50);
    return isVisible;
  }
  const isScrolledIntoViewPartial = (el) => {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;
    var isVisible = (elemTop >= -el.clientHeight + 150) && (elemBottom <= window.innerHeight + el.clientHeight - 150);
    return isVisible;
  }
  window.addEventListener('scroll', () => {
    const resume = document.querySelector('.resumeCont');
    const titles = document.querySelectorAll('.titleContainer')
    const parallax = document.querySelector('.parallax')

    parallax.style.backgroundPosition = `center ${window.pageYOffset/2}px`
    const fadeIns = document.querySelectorAll('.groupContainer, .aboutCont p, .skillsCont p, .contactCont p, .project, .flexedForm')
    if (isScrolledIntoView(resume)) {
      document.querySelector('.navResume').style.display = 'none'
    } else {
      if (window.innerWidth >= 750) {
        document.querySelector('.navResume').style.display = 'block'
      } else {
        document.querySelector('.navResume').style.display = 'none'
      }
    }

    titles.forEach(title => {
      if (isScrolledIntoViewPadded(title)) {
        title.querySelector('h3').style.opacity = 1
        title.querySelector('span').style.transform = 'translate(0vw)'
      }
    })
    fadeIns.forEach(fade => {

      if (isScrolledIntoViewPartial(fade)) {
        fade.style.opacity = 1
        fade.style.transform = 'translate(0px)'
      }

    })
  })

  window.addEventListener('resize', () => {
    const resume = document.querySelector('.resumeCont');
    if (isScrolledIntoView(resume)) {
      document.querySelector('.navResume').style.display = 'none'
    } else {
      if (window.innerWidth >= 750) {
        document.querySelector('.navResume').style.display = 'block'
      } else {
        document.querySelector('.navResume').style.display = 'none'
      }
    }

  })
  return (
    <div className="App"> 

      <Nav />
      <MainClip />
      <About />
      <Skills />
      <Projects />
      <Contact />
    </div>
  );
}

export default App;
