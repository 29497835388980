import React from 'react'
import ProjectList from './ProjectList.js'
import './Projects.scss'
import Project from './Project.js'

const Projects = () => {
    const list = ProjectList.map((project, i) => {
        return (
            <Project project={project} key={i} i={i} />
        )
    })
    return (
        <div className='projectsCont'>
            <div className='titleContainer'>
                <h3>Projects</h3>
                <span></span>
            </div>
            <div className='projectList'>
                {list}
            </div>
        </div>
    )
}

export default Projects