module.exports = {
    resizing : (parentCont) => {
        const lineWidth = document.querySelector(`.${parentCont} .titleContainer span`).clientWidth
        if(window.innerWidth > 750){
            document.querySelector(`.${parentCont} ${parentCont === 'aboutCont' ? '.aboutList' : 'p'}`).style.width = lineWidth + 'px'
            if(parentCont === 'skillsCont'){
                document.querySelectorAll(`.${parentCont} h2`).forEach(h2 => {
                    h2.style.width = lineWidth + 'px'
                })
            }
        }else{
            document.querySelector(`.${parentCont} ${parentCont === 'aboutCont' ? '.aboutList' : 'p'}`).style.width = '100%'
                if(parentCont === 'skillsCont'){
                    document.querySelectorAll(`.${parentCont} h2`).forEach(h2 => {
                        h2.style.width = '100%'
                    })
                }
        }
        
        window.addEventListener('resize', () => {
            const lineWidth = document.querySelector(`.${parentCont} .titleContainer span`).clientWidth
            if(window.innerWidth > 750){
                document.querySelector(`.${parentCont} ${parentCont === 'aboutCont' ? '.aboutList' : 'p'}`).style.width = lineWidth + 'px'
                if(parentCont === 'skillsCont'){
                    document.querySelectorAll(`.${parentCont} h2`).forEach(h2 => {
                        h2.style.width = lineWidth + 'px'
                    })
                }
            }else{
                document.querySelector(`.${parentCont} ${parentCont === 'aboutCont' ? '.aboutList' : 'p'}`).style.width = '100%'
                if(parentCont === 'skillsCont'){
                    document.querySelectorAll(`.${parentCont} h2`).forEach(h2 => {
                        h2.style.width = '100%'
                    })
                }
            }
        })
    }
}
