import React, { useEffect } from 'react'
import SkillList from './SkillList'
import { resizing } from '../../assets/functions'
import './Skills.scss'

const Skills = () => {
    useEffect(() => {
        resizing('skillsCont')
    }, [])
    const frontEnd = SkillList.frontEnd.map((skill, i) => {
        return (
            <div key={i} className='groupContainer'>
                {skill.logo()}
                <span>{skill.name}</span>
            </div>
        )
    })
    const backEnd = SkillList.backEnd.map((skill, i) => {
        return (
            <div key={i} className='groupContainer'>
                {skill.logo()}
                <span>{skill.name}</span>
            </div>
        )
    })
    const workFlow = SkillList.workFlow.map((skill, i) => {
        return (
            <div key={i} className='groupContainer'>
                {skill.logo()}
                <span>{skill.name}</span>
            </div>
        )
    })
    return (
        <div className='skillsCont'>
            <div className='titleContainer'>
                <h3>Skills</h3>
                <span></span>
            </div>

            <p>
                I work towards adding new skills to my repertoire to become a more versatile developer. While the skills listed below are my primary tools of development, I am always experimenting with new frameworks and npm packages.             </p>
            <h2>Client-side</h2>
            <div className='skillList'>
                {frontEnd}
            </div>
            <h2>Server-side</h2>
            <div className='skillList'>
                {backEnd}
            </div>
            <h2>Workflow</h2>
            <div className='skillList'>
                {workFlow}
            </div>

        </div>
    )
}

export default Skills