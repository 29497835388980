import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCaretRight} from '@fortawesome/free-solid-svg-icons'
import { resizing } from '../../assets/functions'
import './About.scss'

const About = () => {
    useEffect(() => {
        resizing('aboutCont')

    }, [])
    return (
        <div className='aboutCont'>
            <div className='titleContainer'>
                <h3>About</h3>
                <span></span>
            </div>
            <div className='aboutList'>



                <h2><FontAwesomeIcon icon={faCaretRight}/>How I Learned</h2>
                <p>
                In the midst of post-grad uncertainty, my grandfather introduced to me the world of web development. He acted as my mentor as I began my independent study of basic HTML, CSS, and Javascript. Once I began grasping the fundamentals of coding, I decided it was time to further my education and invest in attending a coding bootcamp. At DevMountain, a three-month immersive coding bootcamp, I devoted my time to enhancing my skill set. Upon graduation, my newfound skill set allowed me to enter the workforce with confidence.
                </p>

                <h2><FontAwesomeIcon icon={faCaretRight}/>What I Do Best</h2>
                <p>
                In my web development experience, I have always believed to be most proficient with front end technologies such as React. Mobile responsiveness, UX/UI design and code reusability are all things I prioritize when diving into front end work.  Having written a full stack production application, I can say that front-end is more enjoyable. As I continued to delve deeper into javascript, it made learning backend easier through Node.js/Express and allowed me to take core concepts and apply it to the back side of web development.                 </p>

                <h2><FontAwesomeIcon icon={faCaretRight}/>Big Picture</h2>
                <p>
                I challenge myself to write effective, DRY code while expanding my skillset to accommodate the needs of any development stack. Collaborating with developers helps to improve my way of thinking and allows me to approach new challenges in a different light. In the years to come, I aim to have the ability to support/fill any web development role as needed.                 </p>

                <h2><FontAwesomeIcon icon={faCaretRight}/>Outside of Coding</h2>
                <p>
                When I am not coding, I try to stay active physically and mentally. Some of my hobbies include basketball, skateboarding, piano, and more recently, 3D modeling. Trying new things keeps me excited and motivated. With the amount of online resources available, learning is something that will never stop for me.                 </p>
            </div>
        </div>
    )
}

export default About