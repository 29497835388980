import React from 'react'
import portrait from '../../assets/me.JPG'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import './MainClip.scss'

const MainClip = () => {
    return (
        <div className='contForResume'>
            <div className='resumeCont'>
                <a href='https://drive.google.com/file/d/1DZt95kD71bgBmpin9GYx8g-lUPi2Gyy3/view?usp=sharing' target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={faFilePdf} /></a>
            </div>
            <div className='mainClipCont'>
                <span className='parallax'></span>
                <div className='overlay'>
                    <div className='introBox'>
                        <div className='topBox'>
                            <div className='introText'>
                                <h1>Mark Levitas</h1>
                                <span></span>
                                <h2>Web Developer</h2>
                            </div>
                            <img alt='Headshot' src={portrait} />
                        </div>
                    </div>
                </div>
                <div className='downArr' onClick={() => { window.scrollBy({ top: document.querySelector('.aboutCont').getBoundingClientRect().y - 100, behavior: 'smooth' }) }}>
                    <FontAwesomeIcon icon={faAngleDoubleDown} />
                </div>
            </div>

        </div>

    )
}

export default MainClip