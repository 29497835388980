module.exports = [
    {
        title: 'ML Weather',
        type: 'Personal',
        description: `Website displaying information fetched from an API (free plan) and displaying weather information according to requested location based on Latitude and Longitude. Current location services, local storage cookies, and subtle color transitions are used to implement a user friendly experience. All data and weather icons are provided by weatherapi.com`,
        techs: 'HTML5 | React | Redux | Sass | NodeJS | Axios | Express | API | ',
        link: 'https://mlweather.dev/',
        github: 'https://github.com/mlevitas94/weather-API',
        images: [
            `${require('./weatherapi/weathericon.png')}`
        ]
    },
    {
        title: 'Javascript Challenges',
        type: 'Personal',
        description: `Static HTML page using vanilla Javascript to showcase knowledge of basic concepts such as DOM manipulation, API integration, and code organization. The six modules use Javascript in different ways to have a functioning end result, each with the ability for interaction and potential use cases.`,
        techs: 'HTML5 | CSS3 | Javascript | Socket.io | 3rd Party API',
        link: '/jc/jc.html',
        github: 'https://github.com/mlevitas94/JavascriptChallange',
        images: [
            `${require('./jc/jc.jpg')}`
        ]
    },
    {
        title: 'Danielle Elmers',
        type: 'Production',
        description: `Portfolio designed to display past projects completed, personal bio, and contact options which allows visitors to email client directly though the site. Admin page is integrated, allowing the client to update the displayed projects. Switching through pages includes a fading animation for a visual experience. Mobile responsiveness that accommodates all device sizes.`,
        techs: 'HTML5 | React | Axios | Sass | NodeJS | Express | PostgreSQL | React | Amazon S3 | Nodemailer',
        link: 'https://danielleelmers.com/',
        github: 'https://github.com/mlevitas94/Danielle-s-Portfolio',
        images: [
            `${require('./danielmers/landing_image.jpeg')}`
        ]
    },
    {
        title: 'Car Service of South Florida',
        type: 'Production',
        description: `Website for private car service which provides related service information as well as allowing customers to make reservations online, directly emailing the manager. Fully mobile responsive and implemented requested design.`,
        techs: 'HTML5 | React | Axios | Sass | NodeJS | Express | PostgreSQL | React | Amazon S3',
        link: 'https://carserviceofsouthflorida.com/',
        github: null,
        images: [
            `${require('./csosfimages/csosflogo.png')}`
        ]
    },
    {
        title: 'CSOSF Admin',
        type: 'Production',
        description: `An event and customer information management system designed to allow an easy workflow for admins’ ride schedules. An admin can link an event/ride to a saved customer as well linking it to a driver who will receive the event info via texting services. Primarily designed for mobile UI/UX.`,
        techs: 'HTML5 | React | Axios | Sass | NodeJS | Express | PostgreSQL | React | Amazon S3 | Twillio | Nodemailer',
        link: 'https://drive.google.com/file/d/15Kp7ZjyY7RnzDsJgoaxtw1E2SGB0ppa2/view?usp=sharing',
        github: null,
        images: [
            `${require('./csosfimages/csosflogo.png')}`
        ]
    },
    {
        title: 'Newsday Mixed Media',
        type: 'Production',
        description: ' Mixed media page designed to provide information, images, and videos in relation to many different Newsday articles. Using prebuilt Wordpress shortcode and stylesheets, the flow and design was edited into a mobile responsive format that captured Newsday’s online presentation.',
        techs: 'HTML5 | Sass/CSS 3 | Javascript | Wordpress',
        github: null,
        link: 'https://projects.newsday.com/long-island/revisiting-apollo-11/',
        images: [

            `${require('./newsdayimages/newsdaylogo.png')}`,
        ]
    },
    {
        title: 'Spookr',
        type: 'Personal',
        description: `Graduation group project completed at the end of my time in coding bootcamp. This web app is similar to Tinder, but with the fun gimmick of connecting “Home Owners” with “Ghosts”. This project utilizes live front-end updates, heavy user account relations,  Geo-location comparisons, and a fun UI to fit the theme. `,
        techs: 'HTML5 | React | Redux | Sass | NodeJS | Axios | Express | Express Session | Massive | PostgreSQL | Amazon S3 | React S3 | Bcrypt | React-geocode | Socket.io | Bootstrap',
        link: 'https://vimeo.com/386631424',
        github: 'https://github.com/spookr/Spookr',
        images: [
            `${require('./spookrimages/SpookrLogo.png')}`
        ]
    }
]